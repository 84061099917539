header {
  background: #f2f2f2;
  position: fixed !important;
  top: 0;
  width: 150vw;
  margin-left: -25vw;
  padding-left: 25vw;
  padding-top: 0.67em;
  padding-bottom: 0.67em;
  float: center;
  border-bottom: 2px solid #dee4eb;
  z-index: 100;
  color: rgba(0, 0, 0, 0.3);
  font-family: "Aleo", serif;
  font-size: 1.2em;
  font-weight: bold;
}

ul {
  margin: 0px;
}

#topBar p {
  margin: 0;
}

header span,
header a {
  color: #006064 !important;
}

.row {
  display: flex;
  flex-direction: row;
}

.tableContainer {
  margin: 0.5em;
  max-width: 100%;
  overflow-x: auto;
}

.section {
  columns: 300px 3;
  display: block;
  padding: 10px;
}
