.sourceEntry {
  padding: 1em;
  margin: auto;
  float: center;
}

.textInput {
  width: 200px;
  margin-bottom: 1em;
}

.button {
  width: 200px;
}
